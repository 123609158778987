import {getImageUrl} from '../../../utilities/helpers';


class ProductController {
    constructor() {
        'ngInject';
        this.getImageUrl = getImageUrl;

    }

    $onInit() {
        this.product = this.data;
        this.images = this._setPrimaryImage(this._convertImages(this.product.images));
        this.methods = {};
    }

    _convertImages(images) {
        let result = [];

        images.forEach((item) => {
            result.push({
                id: item.id,
                url: getImageUrl(item.imageUrl),
                thumbUrl:  getImageUrl(item.thumbUrl)
            });
        });

        return result;
    }

    _setPrimaryImage(images) {

        if (this.product.primary_media_id) {
            let primaryItemIndex = images.findIndex(image => image.id === this.product.primary_media_id);
            let primaryItem = images.splice(primaryItemIndex, 1);

            return primaryItem.concat(images);
        }

        return images;
    }
}

const ProductComponent = {
    controller: ProductController,
    bindings: {
        data: '<'
    },
    template: require('apply-loader!ng-pug-inline-template-loader!./template.pug')
};

export default ProductComponent;
