import angular from 'angular';

class AppController {

    constructor($state, $window, TitleService, $transitions, $timeout, PagesService, $stateParams) {
        'ngInject';
        this.$state = $state;
        this.$timeout = $timeout;
        this.$window = $window;
        this.titleService = TitleService;
        this.$transitions = $transitions;
        this.pagesService = PagesService;
        this.$stateParams = $stateParams;

        this.phone = process.env.COMPANY_PHONE;
    }

    $onInit() {
        this.toggleMenuBtn = angular.element('.navbar-toggle');
        this.year = new Date().getFullYear();
        this.initPagesMenuItems();

        this.$transitions.onSuccess({}, transition => {
            this.stateName = transition.to().name;
            this.fromStateName = transition.from().name;
            this.titleService.setTitle(transition.params().pageTitle);
        });
    }

    mailTo() {
        const email = process.env.COMPANY_EMAIL;

        this.$window.open('mailto:'+ email, '_blank');
    }

    navigateTo() {
        if (this.stateName == 'home') {
            this.$state.go('about');
        } else {
            this.$state.go('home');
        }
    }

    homeActive() {
        if (this.stateName) {
            return this.stateName.includes('home') || this.stateName =='product' && this.fromStateName.includes('home');
        }
    }

    brandsActive() {
        if (this.stateName) {
            return this.stateName.includes('brand') || this.stateName =='product' && this.fromStateName.includes('brand');
        }
    }

    searchLaunch() {
        if (this.searchText && this.searchText !== '') {
            this.$state.go('search', {query: this.searchText});
            this.searchText = null;
            this.closeMenu();
        }
    }

    closeMenu() {
        this.$timeout(() => {
            this.toggleMenuBtn.click();
        }, 500);
    }

    initPagesMenuItems() {
        let pages = this.pagesService.getStoragePages();
        if (pages) {
            this.pages = pages;
        } else {
            this.pagesService.getPages().then(data => {
                console.log('pages::' , data);
                this.pages = pages;
            });
        }
    }
    navigateToPage(page) {
        this.$state.go('page', {
            slug: page.slug,
        });
    }
}

const AppComponent = {
    controller: AppController,
    template : require('apply-loader!ng-pug-inline-template-loader!./template.pug')
};

export default AppComponent;
