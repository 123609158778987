class AttachmentController {}

const Attachment = {
    controller: AttachmentController,
    bindings: {
        item: '<',
    },
    template: require('apply-loader!ng-pug-inline-template-loader!./template.pug')
};

export default Attachment;
