import {getImageUrl} from '../../../utilities/helpers';


class ProductListController {
    constructor($state, $stateParams) {
        'ngInject';
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.stateName = $state.current.name;
        this.displayedCollection = [];
        this.getImageUrl = getImageUrl;
    }

    openProduct(item) {
        this.$state.go('product',
            {
                categoryID: this.$stateParams.categoryID ? this.$stateParams.categoryID : item.categoryID,
                productID: item.id,
                productSlug: item.slug,
                pageTitle: item.name,
                brand: item.brand,
                prevState: this.$state.current.name
            });
    }
}

const ProductList = {
    controller: ProductListController,
    bindings: {
        products: '<',
        searchText: '<'
    },
    template: require('apply-loader!ng-pug-inline-template-loader!./template.pug')
};

export default ProductList;
