export default class PagesService {

    constructor($http) {
        'ngInject';
        this.$http = $http;
    }

    getStoragePages() {
        return JSON.parse(localStorage.getItem(PagesService.PAGES_STORAGE_KEY));
    }

    getPages() {
        return this.$http.get(PagesService.apiPath, {cache: true});
    }

    getPage(id) {
        return this.$http.get(PagesService.apiPath +'/' + id, {cache: true}).then(resp => resp.data);
    }
}

PagesService.apiPath = '/api/pages';
PagesService.PAGES_STORAGE_KEY = 'adob_menu';
