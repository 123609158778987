export default class CategoryService {
    constructor($http) {
        'ngInject';
        this.$http = $http;
    }

    getCategories() {
        return this.$http.get(CategoryService.apiPath.MAIN_CATEGORIES, {cache: true})
            .then(response => response.data)
            .then(categories =>categories.filter(item => item.active_products > 0));
    }
    getCategory(id) {
        return this.$http
            .get(CategoryService.apiPath.CATEGORY + `/${id}`, {cache: true})
            .then(response => response.data)
            .then(this.filterEmptyCategories);
    }

    filterEmptyCategories(data) {
        data.children = data.children.filter(item => item.active_products > '0');

        return data;
    }
}

CategoryService.apiPath = {
    MAIN_CATEGORIES: 'api/main-categories',
    CATEGORY: 'api/singlecategory'
};
