/* @ngInject */
export default ($stateProvider, $urlRouterProvider, $locationProvider) => {
    $stateProvider
        .state('home', {
            url: '/',
            params: {
                pageTitle: ''
            },
            views: {
                main: {
                    component: 'mainCategories',
                    bindings: {categories: 'mainCategories'}
                }
            },
            resolve: {
                mainCategories: CategoryService => CategoryService.getCategories()
            }
        })
        .state('categoryListing', {
            url: '/kategoria/:categoryID/:categorySlug',
            params: {
                categorySlug: '',
                categoryID: null,
                pageTitle: ''
            },
            views: {
                main: {
                    component: 'categorySelector',
                    bindings: {data: 'categories', productListCount: 'products.length'}
                },
                bottom: {
                    component: 'productList',
                },
                breadcrumbs: {
                    component: 'breadcrumbs',
                    bindings: {data: 'breadcrumbs', categories: 'categories', products: 'products'}
                }
            },
            resolve: {
                categories: (CategoryService, $stateParams) => CategoryService.getCategory($stateParams.categoryID),
                products: (ProductsService, $stateParams) => {
                    if ($stateParams.categoryID) {
                        return ProductsService.getProductsOfCategory($stateParams.categoryID, $stateParams.brand);
                    } else return null;
                },
                breadcrumbs: (BreadcrumbsService, $stateParams) =>
                    BreadcrumbsService.getBreadcrumbs($stateParams.categoryID)
            }
        })
        .state('allproduct', {
            url: '/osszes/:categoryID/:categorySlug',
            views: {
                main: {
                    component: 'productPagingList',
                    bindings: {data: 'products'}
                },
                breadcrumbs: {
                    component: 'breadcrumbs',
                    bindings: {data: 'breadcrumbs'}
                }
            },
            resolve: {
                breadcrumbs: (BreadcrumbsService, $stateParams) =>
                    BreadcrumbsService.getBreadcrumbs($stateParams.categoryID),
                products: (ProductsService, $stateParams) =>
                    ProductsService.getProductsOfCategoryPaginated($stateParams.categoryID)
            }
        })
        .state('allproduct-brand', {
            url: '/osszes/:categoryID/:categorySlug/:brand',
            views: {
                main: {
                    component: 'productPagingList',
                    bindings: {data: 'productsData'}
                },
                breadcrumbs: {
                    component: 'breadcrumbs',
                    bindings: {data: 'breadcrumbs'}
                }
            },
            resolve: {
                breadcrumbs: (BreadcrumbsService, $stateParams) =>
                    BreadcrumbsService.getBreadcrumbs($stateParams.categoryID),
                productsData: (ProductsService, $stateParams) =>
                    ProductsService.getProductsOfCategoryPaginated($stateParams.categoryID, $stateParams.brand)
            }
        })
        .state('product', {
            url: '/termek/:categoryID/:productID/:productSlug',
            params: {
                categoryID: null,
                productSlug: '',
                pageTitle: '',
                brand: '',
                prevState: null
            },
            views: {
                main: {
                    component: 'product',
                    bindings: {data: 'product'}
                },
                breadcrumbs: {
                    component: 'breadcrumbs',
                    bindings: {data: 'breadcrumbs'}
                }
            },
            resolve: {
                product: ($stateParams, ProductsService) =>
                    ProductsService.getSingleProduct($stateParams.productID),
                breadcrumbs: (BreadcrumbsService, $stateParams) =>
                    BreadcrumbsService.getBreadcrumbs($stateParams.categoryID)
            }
        })

        .state('brands', {
            url: '/markak',
            params: {
                pageTitle: 'Márkák',
            },
            views: {
                main: {
                    component: 'mainBrands',
                    bindings: {brands: 'mainBrands'}

                },
                bottom: {
                    component: 'commonBrands',
                    bindings: {brands: 'commonBrands'}
                }
            },
            resolve: {
                mainBrands: BrandService => BrandService.getMainBrands(),
                commonBrands: BrandService => BrandService.getCommonBrands()
            }
        })

        .state('brands-category', {
            url: '/markak/:brand/:categoryID/:categorySlug',
            params: {
                categorySlug: '',
                categoryID: null,
                pageTitle: '',
                brand: ''
            },
            views: {
                breadcrumbs: {
                    component: 'breadcrumbs',
                    bindings: {data: 'breadcrumbs', categories: 'categories', products: 'products'}
                },
                main: {
                    component: 'categorySelector',
                    bindings: { data: 'categories', productListCount: 'products.length'}
                },
                bottom: {
                    component: 'productList',
                    bindings: { products: 'products' }
                }
            },
            resolve: {
                breadcrumbs: (BreadcrumbsService, $stateParams) =>
                    BreadcrumbsService.getBreadcrumbs($stateParams.categoryID),
                categories: ($stateParams, BrandService) =>
                    BrandService.getBrandCategories($stateParams.brand, $stateParams.categoryID),
                products: ($stateParams, ProductsService) => {
                    if ($stateParams.categoryID) {
                        return ProductsService.getProductsOfCategory($stateParams.categoryID, $stateParams.brand);
                    } else return [];
                }
            }
        })
        .state('search', {
            url: '/kereses?query',
            params: {
                pageTitle: '',
            },
            views: {
                main: {
                    component: 'searchResults',
                    bindings: {data: 'results'}
                }
            },
            resolve: {
                results: ($stateParams, SearchService) => SearchService.search($stateParams.query)
            }
        })

        .state('deals', {
            url: '/akciok',
            params: {
                pageTitle: 'Akciók',
            },
            views: {
                main: {
                    component: 'page',
                    bindings: {data: 'pageData'}
                },
                bottom: {
                    component: 'productList'
                }
            },
            resolve: {
                products: ProductsService => ProductsService.getOnSaleProducts(),
                pageData: () => Promise.resolve({title:'Akciós termékek', content:''})
            }
        })
        .state('downloads', {
            url: '/letoltesek',
            params: {
                pageTitle: 'Letöltések',
                state: 'downloads',
            },
            views: {
                main: {
                    template: `
                              <div class="container">
                                 <div class="row">

                                    <div class="col-md-12 adob-downloads-page">
                                        <ul ng-show="$resolve.attachments & $resolve.attachments.length">
                                            <li ng-repeat="item in $resolve.attachments">
                                                  <attachment item="item"></attachment>
                                            </li>
                                        </ul>
                                        <div class="info disabled text-center" ng-show="$resolve.attachments.length === 0">
                                            <a class="btn btn-info btn-lg disabled">
                                                <span class="glyphicon glyphicon-info-sign"></span> <br>
                                                <span>
                                                     Nincs megjeleníthető adat!
                                                </span>
                                             </a>
                                        </div>

                                    </div>

                                 </div>
                              </div>`,
                }
            },
            resolve: {
                attachments: (ContentService) => ContentService.getAttachments()
            }
        })
        .state('korona', {
            url: '/a-maszkokrol',
            params: {
                pageTitle: 'A maszkokról',
            },
            views: {
                main: {
                    template: require('./korona.pug')
                }
            }
        })
        .state('page', {
            url: '/oldal/:slug',
            params: {
                slug: '',
            },
            views: {
                main: {
                    component: 'page',
                    bindings: {data: 'page'}
                }
            },
            resolve: {
                page: (PagesService, $stateParams) => PagesService.getPage($stateParams.slug)
            }
        });

    $urlRouterProvider.otherwise('/');

    $locationProvider.html5Mode({
        enabled: true
    });
};
