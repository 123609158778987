export default class ContentService {
    constructor($http) {
        'ngInject';
        this.$http = $http;
        this.phone = process.env.COMPANY_PHONE;
    }

    getAttachments() {
        return this.$http.get(ContentService.apiPath.ATTACHMENTS, {cache: true})
            .then(response => response.data.media);
    }
}

ContentService.apiPath = {
    ATTACHMENTS: 'api/attachments',
};
